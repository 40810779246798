import axios from 'axios';
import { useRef, useState, useEffect } from "react"
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import { Grid, Tab, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import RegisterShift from '../Components/RegisterShift/RegisterShift';
import TablePagination from '@mui/material/TablePagination';
import 'dayjs/locale/es';
import { useNavigate, useLocation, Navigate, useParams  } from "react-router-dom";
import AgendaDialog from '../Components/AgendaDialog/AgendaDialog';
import Swal from 'sweetalert2';
import { Pagination } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme, lighten } from '@mui/material/styles';

dayjs.locale('es');


const SecondStep = (props) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const { token, services, institucion, prepaid, patientName, onFinish, onLoading, limitDate, observation } = props;
    const [openDialog, setOpenDialog] = useState(false);
    const [dataForm, setDataForm] = useState({
        datePicked: dayjs(new Date()).format('YYYY/MM/DD'),
        selected: -1,
        formatDate: dayjs(new Date()).format('YYYY/MM/DD')
    });
    const [doctors, setDoctors] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [openAgenda, setOpenAgenda] = useState(false);
    const [hour, setHour] = useState('');
    const [selectedShift, setSelectedShift] = useState(null);
    const [page, setPage] = useState(1);
    const [hasDataData, setHasDataData] = useState(true);
    const navigate = useNavigate();
    const [fichaId, setFichaId] = useState(null);



    useEffect(() => {
        getDoctors();
        getFichaId();
    }, [token]);

    const getDoctors = async () => {
        try {
            const response = await axios.post(`${backendUrl}/doctors`,
                JSON.stringify({ os_id: prepaid.id, service_id: services.id, institution_id: institucion.id }),
                {
                    headers: { 'Content-Type': 'application/json', Authorization: token }
                });

                if(response.data.data !== null){
                    setDoctors(response.data.data);
                }
        } catch (error) {
            Swal.fire({
                title: 'Error al encontrar datos ',
                icon: 'error',
              })
        }
    }

    const handleOpenAgenda = (value) => {
        setOpenAgenda(true);
        setSelectedShift(value);
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: lighten('#1C7D89', 0.5),
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));



    const fetchDoctorData = async (doctorId) => {
        onLoading()
        setTableData([]);
        try {
            const response = await axios.post(`${backendUrl}/appointments/doctor`,
                JSON.stringify({ os_id: prepaid.id, service_id: services.id, institution_id: institucion.id, date: dataForm.formatDate, doctor_id: doctorId }),
                {
                    headers: { 'Content-Type': 'application/json', Authorization: token }
                });

                if(response.data.data.appointments !== null){
                    setTableData(response.data.data.appointments);
                    setHasDataData(true);
                    setLoading(false);
                    setPage(1);
                    onFinish();
                }else{
                    setTableData([]);
                    setHasDataData(false);
                    setLoading(false);
                    onFinish();
                }
                
                    onFinish();
                
        } catch (error) {
            setTableData([]);
            setHasDataData(false);
            setLoading(false);
            onFinish();
        }
    }

    const getFichaId = async () => {
        try {
          const response = await axios.get(`${backendUrl}/patient/${patientName.dni}/institution/${institucion.id}/status`, {
            headers: {
              Authorization: token,
            },
          })
          if(response.data.status_code === 200){
            setFichaId(response.data.data);
          }
          
          else{
            setFichaId(null);
          }
        } catch (error) {
            setFichaId(null);
        }
      }

    const fetchAllDoctorsData = async () => {
        onLoading()
        setTableData([]);
        try {
            const response = await axios.post(`${backendUrl}/appointments`,
            JSON.stringify({ os_id: prepaid.id, service_id: services.id, institution_id: institucion.id, date: dataForm.formatDate}),
                {
                    headers: { 'Content-Type': 'application/json', Authorization: token }
                });

                if(response.data.data !== null){
                    setTableData(response.data.data);
                    setLoading(false);
                    setHasDataData(true);
                    setPage(1);
                    onFinish();
                }else{
                    setTableData([]);
                    setHasDataData(false);
                    setLoading(false);
                    onFinish();
                }
                    setLoading(false);
                    onFinish();
        } catch (error) {
            setHasDataData(false);
            setTableData([]);
            setLoading(false);
            onFinish();
        }
    }

    const handleDoctorChange = (event, newValue) => {
        setSelectedDoctor(newValue);
        if (newValue && !newValue.isAllDoctorsOption) {
            fetchDoctorData(newValue.id);
        } else {
            fetchAllDoctorsData();
        }
    }

    const handleTurnoClick = (value) => {
        if(fichaId === null){
            Swal.fire({
                title: 'No se encuentra la ficha del paciente',
                text: 'Por favor, verifique que el paciente se encuentre registrado en la institución',
                icon: 'error',
              })
              return;
        }
        setSelectedShift(value);
        setOpenDialog(true);
    }

    const handleDateChange = (newValue) => {
        setDataForm({ ...dataForm, formatDate: dayjs(newValue).format('YYYY/MM/DD'), datePicked: newValue });
        setTableData([]);
        setSelectedDoctor(null);
         
    }

    function formatHour(hour) {
        if ( hour.length === 3) {
            const hours = hour.slice(0, 1);
              const minutes = hour.slice(1);
              return `${hours}:${minutes}`;
          }
    
        const hours = hour.slice(0, 2);
        const minutes = hour.slice(2);
        return `${hours}:${minutes}`;
    }

   

    const rowsPerPage = 10;
      

      const emptyRows = rowsPerPage - Math.min(rowsPerPage, tableData.length - (page - 1) * rowsPerPage);

      // Calcula el número total de páginas
  const count = Math.ceil(tableData.length / rowsPerPage);

   // Maneja el cambio de página
   const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Calcula los datos a mostrar en la página actual
  const dataToShow = tableData.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    const doctorOptions = [
        { first_name: 'Ver todos',last_name: 'los prestadores', isAllDoctorsOption: true },
        ...(doctors || []),
    ];

    return (
        <div>
            {
                !hasDataData && <Typography variant="h5" sx={{ mb: 3 }}>No se encontraron turnos</Typography>
            }
            {
                doctors.length === 0 && <Typography variant="h5" sx={{ mb: 3 }}>No se encuentran prestadores para el servicio requerido</Typography>
            }
            {doctors.length > 0 && (
                <Grid container justifyContent="center"  sx={{ mb: 5, gap: 2 }}>
                    <Autocomplete
                    style={{ width: 500 }}
                    disableClearable
                options={doctorOptions}
                getOptionLabel={(option) =>  `${option.first_name.toUpperCase()} ${option.last_name.toUpperCase()}`}
                value={selectedDoctor}
                onChange={handleDoctorChange}
                renderInput={(params) => (
                    <TextField {...params} label="Seleccionar Doctor" />
                )}
                isOptionEqualToValue={(option, value) => option.first_name === value.first_name}
                renderOption={(props, option) => (
                    <li {...props}>
                        {option.isAllDoctorsOption ? 'Ver todos los prestadores' : `${option.first_name.toUpperCase()} ${option.last_name.toUpperCase()}`}
                    </li>
                )}
            />
                
            <Box sx={{ display: "flex", flexDirection: "row" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        defaultValue={dayjs(new Date()).format('YYYY/MM/DD')}
                        label='Selecciona una fecha'
                        openTo="day"
                        value={dataForm.datePicked}
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} />}
                        format="YYYY/MM/DD"
                        minDate={dayjs().toDate()}
                        maxDate={dayjs().add(limitDate ? limitDate : 60, 'day').toDate()}
                    />
                </LocalizationProvider>
            </Box>
            <Grid item xs={12}>
            {
                tableData && (
                tableData?.length !== 0 && (
                    <>
                    <TableContainer component={Paper}
                    sx={{ maxHeight: 440, overflow: 'auto' }}
                    >
                <Table stickyHeader sx={{ width: '100%' }} >
                    {
                        tableData?.length !== 0 && (
                            <TableHead>
                        <StyledTableRow>
                            <StyledTableCell>Nombre</StyledTableCell>
                            <StyledTableCell>Servicio</StyledTableCell>
                            <StyledTableCell>Fecha</StyledTableCell>
                            <StyledTableCell>Hora</StyledTableCell>
                            <StyledTableCell>Institución</StyledTableCell>
                            <StyledTableCell>Edificio</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                        </StyledTableRow>
                    </TableHead>
                        )
                    }
                    
                    <TableBody>
            {
            tableData && (tableData.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((row) => (
              <StyledTableRow >
                <StyledTableCell>{`${row.doctor.first_name} ${row.doctor.last_name}` || ""}</StyledTableCell>
                <StyledTableCell>{row.service.name}</StyledTableCell>
                <StyledTableCell>{row.date}</StyledTableCell>
                <StyledTableCell>{formatHour(row.hour_start)}</StyledTableCell>
                <StyledTableCell>{row.institution.short_name}</StyledTableCell>
                <StyledTableCell>{row.building}</StyledTableCell>
                <StyledTableCell>
                  <Button variant="contained" onClick={() => handleTurnoClick(row)}>Sacar Turno</Button>
                </StyledTableCell>
                <StyledTableCell>
                  <Button variant="contained" onClick={() => handleOpenAgenda(row)}>Ver Agenda</Button>
                </StyledTableCell>
              </StyledTableRow>
            )))}
            {emptyRows > 0 && (
              <StyledTableRow style={{ height: 53 * emptyRows }}>
                <StyledTableCell colSpan={6} />
              </StyledTableRow>
            )}
          </TableBody>
                </Table>
            </TableContainer>
            {tableData?.length !== 0 && (<Pagination
                count={count}
                page={page}
                onChange={handleChangePage}
                color="primary"
            />)}
            </>
            
                ))
            }
            </Grid>
            
            
            </Grid>
            )}
            {
                openDialog && (
                    <RegisterShift 
                    doctor={selectedShift.doctor} 
                    date={selectedShift.date} 
                    hour={formatHour(selectedShift.hour_start)} 
                    open={openDialog} onClose = {() => setOpenDialog(false)} 
                    institution ={selectedShift.institution}
                    service = {selectedShift.service}
                    patientName = {patientName.name + " " + patientName.surname}
                    prepaid = {selectedShift.os}
                    cronoId = {selectedShift.cro_id}
                    patientId = {patientName.id}
                    fichaId = {fichaId}
                    building = {selectedShift.building}
                    observation = {observation}
                    
                    />
                )
            }
            {
                openAgenda && (
                    <AgendaDialog 
                    isOpen={openAgenda}
                    onClose={() => setOpenAgenda(false)}
                    data={tableData}
                    patientId={patientName.id}
                    patientName={patientName.name + " " + patientName.surname}
                    prepaid={selectedShift.os}
                    token={token}
                    service={selectedShift.service}
                    institution={selectedShift.institution}
                    doctorId={selectedShift.doctor}
                    doctoName={selectedShift.doctor.first_name + " " + selectedShift.doctor.last_name}
                    comSem={selectedShift.date}
                    fichaId={fichaId}
                    building={selectedShift.building}
                    hourStart={selectedShift.hour_start}
                    hourEnd={selectedShift.hour_end}
                    observation={observation}
                    limitDate={limitDate}
                    />
                )
            }
        </div>
    )
}

export default SecondStep;