import React from 'react';
import { Box, Autocomplete, TextField } from '@mui/material';

const ThirdStep = ({ formData, selectedPrepaids, prepaids, handlePrepaid, selectedService, services, handleService }) => {
  return (
    <>
      {formData.isInstitutionSelected && (
        <Box justifyContent="center" sx={{ display: "flex", flexDirection: "row", pt: 2, mb: 2 }}>
          <Autocomplete
            noOptionsText="No hay obras sociales"
            value={selectedPrepaids}
            disableClearable
            id="checkboxes-tags-demo"
            options={prepaids}
            getOptionLabel={(option) => option?.name}
            style={{ width: 500 }}
            onChange={handlePrepaid}
            renderInput={(params) => <TextField {...params} label="Obra social" />}
          />
        </Box>
      )}
      <br />
      {formData.isPrepaidsSelected && (
        <Box justifyContent="center" sx={{ display: "flex", flexDirection: "row", pt: 2, mb: 2 }}>
          <Autocomplete
            noOptionsText="No hay servicios"
            value={selectedService}
            disableClearable
            id="checkboxes-tags-demo"
            options={services}
            getOptionLabel={(option) => option?.name}
            style={{ width: 500 }}
            onChange={handleService}
            renderInput={(params) => <TextField {...params} label="Servicios" />}
          />
        </Box>
      )}
    </>
  );
};

export default ThirdStep;