import React, { useState } from 'react';
import { Grid, Card, CardActionArea, CardMedia, Typography } from '@mui/material';
import Imagen from '../../assets/hospitalFrente.webp';

const InstitutionSelect = (props) => {
    const { institutionImages, onSelectImage, selectedInstitution } = props;
    const [selectedImage, setSelectedImage] = useState(selectedInstitution?.id);
    console.log(selectedInstitution, 'institution selected');
  
    const handleSelectImage = (image) => {
        onSelectImage(image);
        setSelectedImage(image.id);
    };
  
    return (
      <Grid container spacing={2}>
        {institutionImages?.map((image) => (
          <Grid item xs={4} key={image.id}>
            <Card
              onClick={() => handleSelectImage(image)}
              sx={{
                filter: selectedImage && selectedImage !== image.id ? 'grayscale(100%)' : 'none',
                opacity: selectedImage && selectedImage !== image.id ? 0.5 : 1,
                transition: 'all 0.3s ease-in-out',
                boxShadow: 'none', 
              border: 'none'
              }}
            >
              <CardActionArea>
                <CardMedia
                  component="img"
                  style={{ height: '170px',  borderRadius: '30%', margin: 'auto', width: '65%' }}
                  image={image.url_image}
                  alt={image.short_name}
                />
                <Typography variant="h6" align="center">
                  {image.short_name}
                </Typography>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };
  
  export default InstitutionSelect;