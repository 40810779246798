import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Drawer, List, ListItem, ListItemText, Container, Grid, Box, Divider  } from '@mui/material';
import Navbar from '../navBar/Navbar';
import Cards from '../Card/Cards';
import NavbarListDrawer from '../navBar/NavListDrawer';
import { useNavigate, useLocation, Navigate  } from "react-router-dom";
import Cookies from "js-cookie";
import PositionedSnackbar from '../Accesibility/Alert';
import { CardMedia, CardActionArea} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from 'react-router-dom';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import NuevoTurno from '../../assets/NuevoTurno.jpg';
import MisDatos from '../../assets/MisDatos.jpg';
import HistorialDeTurnos from '../../assets/HistorialDeTurnos.jpg';
import CssBaseline from '@mui/material/CssBaseline';
import AppNavBar from '../AppBar/AppNavBar';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AddchartIcon from '@mui/icons-material/Addchart';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TopicOutlinedIcon from '@mui/icons-material/TopicOutlined';
import Swal from 'sweetalert2';
import AppImage from '../../assets/AppBar.jpg'


const theme = createTheme({
  components: {
    MuiCardMedia: {
      styleOverrides: {
        img: {
          height: '300px',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#2c8d99',
    },
  },
});

const themeCard = createTheme({
  palette: {
    primary: {
      main: '#1c7d89', // Cambia esto por el código de color verde que quieras usar
    },
  },
});

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const msj = location.state?.msj || '';
  const [token, setToken] = useState();
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = React.useState(true);




  useEffect(() => {
    // Recuperar el token de las cookies al montar el componente
    const storedToken = Cookies.get("token");
    if (storedToken) {
      if (!token) {
        setToken(storedToken);
      }
    }
  }, [setToken, token]);

  const showAlert = () =>{
    if (msj !== '') {
      Swal.fire({
        title: 'Error al sacar turno',
        text: msj || 'No se encontraron pacientes para sacar turno',
        icon: 'error',
    });
    }
  }


  useEffect(() => {
    // Almacenar el token en las cookies cuando llega a través de location.state.token
    const tokenFromLocation = location.state?.token;
    const emailFromLocation = location.state?.email;
    const idFromLocation = location.state?.id;
    if (tokenFromLocation && emailFromLocation) {
      // Almacena el token en las cookies con una fecha de vencimiento (por ejemplo, 1 hora)
      Cookies.set("token", tokenFromLocation, { expires: 1 / 24 });
      Cookies.set("email", emailFromLocation, { expires: 1 / 24 });
      Cookies.set("id", idFromLocation, { expires: 1 / 24 });

    }
  }, [location.state?.token]);

  

  return (
    <>
    {showAlert()}

    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        
        <AppNavBar />
        
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
            <Box  style={{marginLeft: '30px', marginTop: '150px'}} sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 2, mr: 5}}>
            <Box sx={{width: 600}}>
          <Link to={{ pathname: '/appointments',
              state: { token: '/home' }
              }}
              style={{ textDecoration: 'none' }}>
               <Card sx={{ 
                backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[200]
                    : theme.palette.grey[900],
                height: 200,
                mt: 5,
                transition: "0.2s",
                "&:hover": {
                  transform: "scale(1.05)",
                  cursor: "pointer",
                },
              
              }}>
                <ThemeProvider theme={themeCard}>
                <CardContent>
                  <Box display="flex" alignItems="center">
                    <EventNoteIcon color="primary" fontSize="large"/>
                    <Typography variant="h4" component="div" ml={1} color="primary">
                      TURNOS
                    </Typography>
                  </Box>
                  <Divider />
                  <Typography variant="h5" color="text.secondary" mt={4} align="center">
                    Accede a tus turnos desde aquí
                  </Typography>
                </CardContent>
                </ThemeProvider>
              </Card>
              
          </Link>
          </Box>
          {/* <Box sx={{width: 600}}>
          <Link to={{ pathname: '/',
              state: { token: '/home' }
              }}
              style={{ textDecoration: 'none' }}>
               <Card sx={{ 
                backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[200]
                    : theme.palette.grey[900],
                height: 200,
                mt: 5,
                transition: "0.2s",
                "&:hover": {
                  transform: "scale(1.05)",
                  cursor: "pointer",
                },
              
              }}>
                <ThemeProvider theme={themeCard}>
                <CardContent>
                  <Box display="flex" alignItems="center">
                    <AddchartIcon color="primary" fontSize="large"/>
                    <Typography variant="h4" component="div" ml={1} color="primary">
                      CARTILLA MÉDICA
                    </Typography>
                  </Box>
                  <Divider />
                  <Typography variant="h5" color="text.secondary" mt={4} align="center">
                    Conocé a nuestro equipo médico
                  </Typography>
                </CardContent>
                </ThemeProvider>
              </Card>
              
          </Link>
          </Box> */}
          <Box sx={{width: 600}}>
          <Link to={{ pathname: '/verify-beneficiary',
              state: { token: '/home' }
              }}
              style={{ textDecoration: 'none' }}>
               <Card sx={{ 
                backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[200]
                    : theme.palette.grey[900],
                height: 200,
                mt: 5,
                transition: "0.2s",
                "&:hover": {
                  transform: "scale(1.05)",
                  cursor: "pointer",
                },
              
              }}>
                <ThemeProvider theme={themeCard}>
                <CardContent>
                  <Box display="flex" alignItems="center">
                    <PeopleAltIcon color="primary" fontSize="large"/>
                    <Typography variant="h4" component="div" ml={1} color="primary">
                      GRUPO FAMILIAR
                    </Typography>
                  </Box>
                  <Divider />
                  <Typography variant="h5" color="text.secondary" mt={4} align="center">
                    Administra tus datos y los de tu familia aquí
                  </Typography>
                </CardContent>
                </ThemeProvider>
              </Card>
              
          </Link>
          </Box>
          {/* <Box sx={{width: 600}}>
          <Link to={{ pathname: '/',
              state: { token: '/home' }
              }}
              style={{ textDecoration: 'none' }}>
               <Card sx={{ 
                backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[200]
                    : theme.palette.grey[900],
                height: 200,
                mt: 5,
                transition: "0.2s",
                "&:hover": {
                  transform: "scale(1.05)",
                  cursor: "pointer",
                },
              
              }}>
                <ThemeProvider theme={themeCard}>
                <CardContent>
                  <Box display="flex" alignItems="center">
                    <TopicOutlinedIcon color="primary" fontSize="large"/>
                    <Typography variant="h4" component="div" ml={1} color="primary">
                      TRÁMITES
                    </Typography>
                  </Box>
                  <Divider />
                  <Typography variant="h5" color="text.secondary" mt={4} align="center">
                    Revisa el estado de tus trámites
                  </Typography>
                </CardContent>
                </ThemeProvider>
              </Card>
              
          </Link>
          </Box> */}
            </Box>
              
        </Box>
      </Box>
    </ThemeProvider>
      
 


    </>
  )
}

export default Home